import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const CreateGenres = (props) => {
  return (
    <Create {...props} title={'Добавление нового жанра'}>
      <SimpleForm redirect={'list'}>
        <TextInput fullWidth={true} source="name" label={'Название жанра'}/>
        <TextInput fullWidth={true} source="templateName" label={'Шаблон для QR кода'}/>
      </SimpleForm>
    </Create>
  );
};
