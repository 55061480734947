import LabelIcon from '@material-ui/icons/Label';
import React from 'react';
import { ChipField } from 'react-admin';

export const GenreOrEmptyChip = (props) => {
  return !props.record
    ? null
    : <ChipField
      {...props}
      icon={<LabelIcon/>}
      source="name"
      variant={'outlined'}
      color={'primary'}
      size={'small'}/>;
}