import { config } from '../config';
const { apiUri: apiUrl } = config;

export const authProvider = {
  login: ({ username, password }) =>  {
    const authToken = process.env.REACT_APP_BEARER_AUTH_TOKEN ?? 'sery_vecret_apmin_danel';
    const request = new Request(`${apiUrl}/auth`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${authToken}` }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return  response.json();
      })
      .then(({ token }) => {
        localStorage.setItem('token', token);
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkAuth: () => localStorage.getItem('token')
    ? Promise.resolve()
    : Promise.reject(),
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};
