const preCommitHandler = (action, values) => {
  Object.keys(values).map(key => values[key].importFromCsv = true);
  return values;
};

export const ImportConfig = {
  preCommitCallback: preCommitHandler,
  parseConfig: {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: 'greedy',
  }
}