import axios from 'axios';
import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import qs from 'qs';

import { config } from '../config';

const { apiUri: apiUrl } = config;

const httpClient = fetchUtils.fetchJson;

const authToken = process.env.REACT_APP_BEARER_AUTH_TOKEN ?? 'sery_vecret_apmin_danel';

const headersWithAuthToken = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Authorization: `Bearer ${authToken}`,
};

const DataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: [field, order],
      range: [page, perPage],
      filter: params.filter,
    };
    const url = `${apiUrl}/${resource}`;

    try {
      const { data = [] } = await axios({
        headers: headersWithAuthToken,
        params: qs.stringify(query, {arrayFormat: 'brackets'}),
        method: 'get',
        url,
      });
      return {
        data: data.rows ? data.rows || [] : data,
        total: data.total ? data.total || 0 : data.length > 0 ? data.length : 0,
      };
    } catch (err) {
      console.error('--err', err);
      return { data: [], total: 0 };
    }
  },
  
  getOne: async (resource, params) => {
    if (!params.id) {
      return;
    }
    const query = {
      filter: params.filter,
    };
    const url = `${apiUrl}/${resource}/${params.id}`;
    try {
      const { data: [bookData] = {} } = await axios({
        headers: headersWithAuthToken,
        params: qs.stringify(query, {arrayFormat: 'brackets'}),
        method: 'get',
        url,
      });
      return { data: bookData };
    } catch (err) {
      console.log('--err', err);
    }
  },
  
  getMany: async (resource, params) => {
    const query = {
      filter: params.filter,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    try {
      const { data = [] } = await axios({
        headers: headersWithAuthToken,
        params: qs.stringify(query, {arrayFormat: 'brackets'}),
        method: 'get',
        url,
      });
      return { data };
    } catch (err) {
      console.log('--err', err);
    }
  },
  
  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    
    return httpClient(url, { headers: headersWithAuthToken }).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },
  
  update: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    try {
      const payload = {
        headers: {
          ...headersWithAuthToken,
          'Content-Type': 'application/json',
        },
        data: params,
        method: 'patch',
        url,
      };
      const { data = [] } = await axios(payload);
      return { data };
    } catch (err) {
      console.log('--err', err);
    }
    return { data: [] };
  },

  
  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      headers: {
        ...headersWithAuthToken,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  
  create: async (resource, params) => {
    console.log('--params', params);
    const url = `${apiUrl}/${resource}`;
    try {
      const payload = {
        headers: {
          ...headersWithAuthToken,
          'Content-Type': 'application/json',
        },
        data: params.data,
        method: 'post',
        url,
      };
      const result = await axios(payload);
      return {
        data: {
          ...params.data,
          id: result.data.id
        },
      };
    } catch (error) {
      return console.error(error);
    }
  },

  delete: async (resource, params) => {
    try {
      const payload = {
        headers: {
          ...headersWithAuthToken,
          'Content-Type': 'application/json',
        },
        method: 'delete',
        url: `${apiUrl}/${resource}/${params.id}`,
      };
      const { data } = await axios(payload);
      return { data };
    } catch (error) {
      return Promise.reject(error);
    }
  },
  
  deleteMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    };
    try {
      const payload = {
        headers: {
          ...headersWithAuthToken,
          'Content-Type': 'application/json',
        },
        method: 'delete',
        url: `${apiUrl}/${resource}?${stringify(query)}`,
      };
      return await axios(payload);
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

export default DataProvider;
