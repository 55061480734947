import React from 'react';
import {
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { ScreenNameChoices } from './ConstsScreen';

export const EditScreens = (props) => {
  return (
    <Edit {...props} title={'Редактирование нового экрана бота'}>
      <SimpleForm redirect={'list'}>
        <SelectInput source={'screen'} choices={ScreenNameChoices} label={'Название экрана'}/>
        <TextInput fullWidth={true} source="text"/>
      </SimpleForm>
    </Edit>
  );
};
