import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const EditGenres = (props) => {
  return (
    <Edit {...props} title={'Редактирование жанра'}>
      <SimpleForm redirect={'list'}>
        <TextInput fullWidth={true} source="name" label={'Название жанра'}/>
        <TextInput fullWidth={true} source="templateName" label={'Шаблон для QR кода'}/>
      </SimpleForm>
    </Edit>
  );
};
