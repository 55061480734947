import React from 'react';
import { ChipField } from 'react-admin';

export const LocationWithNotFoundOption = props => {
  return <ChipField
    {...props}
    source="name"
    variant={'outlined'}
    color={'primary'}
    size={'small'}/>;
};