import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
} from 'react-admin';

const SearchFilter = props => {
  return (
    <Filter { ...props } variant={'outlined'}>
      <TextInput
        alwaysOn
        resettable
        source="q"
        variant={'outlined'}
        label={props.label}
      />
    </Filter>
  );
};

export const ListGenres = props => (
  <List
      {...props}
      filters={<SearchFilter label={'Поиск по названию жанра'}/>}
      sort={{ field: 'name', order: 'ASC' }}
      title={'Жанры'}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label={'Название жанра'} />
      <TextField source="templateName" label={'Шаблон для QR кода'} />
    </Datagrid>
  </List>
);
