import React from 'react';
import {
  CreateButton,
  Datagrid,
  EditButton,
  List,
  ReferenceArrayField,
  ReferenceField,
  SelectField,
  SingleFieldList,
  TextField,
} from 'react-admin';

import { BookStatusChoices } from './Books.constants';
import { Box, Typography } from '@material-ui/core';
import LabelIcon from '@material-ui/icons/Label';
import { ImportButton } from 'react-admin-import-csv';
import { BooksFilters } from './Components/BooksFilter';

import { ExpandAdditionalProps } from './Components/ExpandAdditionalProps';
import { GenreOrEmptyChip } from './Components/GenreOrEmptyChip';
import { ImportConfig } from './Components/ImportConfig';
import { ListActions } from './Components/ListActions';
import { LocationWithNotFoundOption } from './Components/LocationWithNotFoundOption';

const Empty = ({ basePath, ...props }) => {
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        В библиотеке ещё нет книг
      </Typography>
      <Typography variant="body1">
        Нажмите кнопку создать для добавления одной книги или импортировать для добавления книг из файла
      </Typography>
      <Typography variant="body1">
        Пример файла для импорта можно скачать по <a target={'_blank'} href={'/book.csv'} rel="noopener noreferrer">ссылке</a>.
      </Typography>
      <Typography variant="body1">
        Названия столбцов и их порядок должны соответствовать примеру.
      </Typography>
      <CreateButton basePath={basePath} />
      <ImportButton label={'Импортировать'} {...props} {...ImportConfig} />
    </Box>
  );
}

export const ListBooks = props => {
  return (
    <List {...props}
          actions={<ListActions/>}
          empty={<Empty/>}
          filters={<BooksFilters/>}
          sort={{field: 'bookNumber', order: 'ASC'}}
          title={'Список книг'}
    >
      <Datagrid
        expand={<ExpandAdditionalProps/>}
        rowClick={'expand'}
      >
        <TextField source="bookNumber" label={'Номер'}/>
        <ReferenceArrayField reference="genres" source="genres" label={'Жанры'}>
          <SingleFieldList style={{ marginTop: '-4px' }}>
            <GenreOrEmptyChip
              icon={<LabelIcon />}
              source="name"
              variant={ 'outlined' }
              color={ 'primary' }
              size={ 'small' }/>
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="names[0].fullName" label={'Полное название'}/>
        <TextField source="authors[0].name" label={'Автор'}/>
        <SelectField
          source="status"
          label={'Статус'}
          choices={BookStatusChoices}
        />
        <ReferenceField reference="locations" source="location" label={'Локация'}>
          <LocationWithNotFoundOption/>
        </ReferenceField>
        <EditButton/>
      </Datagrid>
    </List>
  );
};
