import React from 'react';
import { CreateButton, ExportButton, TopToolbar } from 'react-admin';
import { ImportButton } from 'react-admin-import-csv';
import { ImportConfig } from './ImportConfig';

export const ListActions = props => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter
  } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <ImportButton label={'Импортировать'} {...props} {...ImportConfig}/>
    </TopToolbar>
  );
};
