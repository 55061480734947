import React from 'react';
import { AutocompleteInput, Filter, ReferenceInput, TextInput } from 'react-admin';

export const BooksFilters = props => {
  return (
    <Filter { ...props } variant={'outlined'}>
      <TextInput
        alwaysOn
        resettable
        source="q"
        variant={'outlined'}
        label={'Поиск название/автор'}
      />
      <ReferenceInput
        alwaysOn
        allowEmpty
        emptyText={'Все локации'}
        label={ 'Локация' }
        source={ 'location' }
        reference="locations"
      >
        <AutocompleteInput
          optionText="name"
          variant={'outlined'}
        />
      </ReferenceInput>
    </Filter>
  );
};