import * as React from "react";
import { Admin, Login, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import _ from 'lodash';

import DataProvider from './Provider/DataProvider';
import { CreateScreens } from './Resources/Screens/CreateScreens';
import { ListScreens } from './Resources/Screens/ListScreens';
import { EditScreens } from './Resources/Screens/EditScreens';
import { ListUsers } from './Resources/Users/ListUsers';
import { ListBooks } from './Resources/Books/ListBooks';
import { CreateBooks } from './Resources/Books/CreateBooks';
import { EditBooks } from './Resources/Books/EditBooks';
import { CreateLocations, EditLocations, ListLocations } from './Resources/Locations';
import { ListGenres } from './Resources/Genres/ListGenres';
import { CreateGenres } from './Resources/Genres/CreateGenres';
import { EditGenres } from './Resources/Genres/EditGenres';
import { EditUsers } from './Resources/Users/EditUsers';
import { Business, DynamicFeed, MenuBook, PhonelinkSetup, RecentActors, Settings } from '@material-ui/icons';
import { authProvider } from './Provider/AuthProvider';
import { myTheme } from './Provider/ThemeProvider';
import additionalI18NProvide from './Provider/i18nProvider';
import {ListStats} from './Resources/Stats/ListStats';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { ListSettings } from './Resources/Settings/ListSettings';
import { EditSettings } from './Resources/Settings/EditSettings';
const i18nProvider = polyglotI18nProvider(() => ({
  ..._.merge(russianMessages, additionalI18NProvide),
}), 'ru');

const MyLoginPage = () => (
  <Login backgroundImage="/books-5433432_1920.jpg"/>
);

const App = () =>
  <Admin
    authProvider={authProvider}
    dataProvider={DataProvider}
    i18nProvider={i18nProvider}
    loginPage={MyLoginPage}
    theme={myTheme}
  >
    <Resource
      create={CreateLocations}
      edit={EditLocations}
      icon={Business}
      list={ListLocations}
      name="locations"
      options={{ label: 'Локации' }}
    />
    <Resource
      icon={RecentActors}
      list={ ListUsers }
      name="users"
      edit={ EditUsers }
      options={ { label: 'Читатели' } }
    />
    <Resource
      create={ CreateBooks }
      edit={ EditBooks }
      icon={MenuBook}
      list={ ListBooks }
      name="books"
      options={ { label: 'Книги' } }
    />
    <Resource
      create={CreateGenres}
      edit={EditGenres}
      icon={DynamicFeed}
      list={ListGenres}
      name="genres"
      options={{ label: 'Жанры' }}
    />
    <Resource
      create={ CreateScreens }
      edit={ EditScreens }
      icon={PhonelinkSetup}
      list={ ListScreens }
      name="screens"
      options={ { label: 'Экраны бота' } }
    />
    <Resource
      icon={AssessmentOutlinedIcon}
      list={ ListStats }
      name="stats"
      options={ { label: 'Отчёты' } }
    />
    <Resource
      icon={Settings}
      name={'settings'}
      list={ListSettings}
      edit={EditSettings}
      options={{ label: 'Настройки' }}
    />
  </Admin>;

export default App;
