import * as PropTypes from 'prop-types';
import React from 'react';
import {
  Datagrid, downloadCSV,
  ExportButton,
  Filter,
  FunctionField,
  List,
  NumberField,
  ReferenceInput,
  SelectInput,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';

import { Reports } from './Stats.constants';
import LinearProgress from '@material-ui/core/LinearProgress';

const ListActions = props => {
  const {
    className,
    total,
    resource,
    currentSort,
    filterValues,
    exporter
  } = props;
  return (
    <TopToolbar className={className}>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </TopToolbar>
  );
};

function QuickFilter(props) {
  return null;
}

QuickFilter.propTypes = {
  defaultValue: PropTypes.bool,
  source: PropTypes.string,
  label: PropTypes.string
};
const StatsFilters = (props) => {
  const isByLocationFilterVisible = props.filterValues.selectedReport === 'allBooksByLocation'
  return (
    <Filter { ...props } variant={'outlined'}>
      <SelectInput source={'selectedReport'} choices={Reports} alwaysOn label={'Отчёт'} />
      <ReferenceInput
        reference="locations"
        source="location"
        label={'Локация'}
        alwaysOn={isByLocationFilterVisible}
      >
        <SelectInput optionText="name" variant={'outlined'}/>
      </ReferenceInput>
    </Filter>
  );
};

const LinkToBookEdit = ({ record }) => {
  let link;
  let caption;
  if (record?.report === 'booksOnHands') {
    link = `/#/books/${record.id}`;
    caption = record.names[0].fullName;
  } else if (record?.report === 'residentsWithExpiredReturn') {
    link = record.book?.id ? `/#/users/${record.book?.id}` : undefined;
    caption = record.book?.names?.[0]?.fullName ?? 'Название книги не указано';
  }
  return link && caption
    ? <a href={link}>
      {caption}
    </a>
    : <div>{caption} </div>;
};

const LinkToUserEdit = ({ record }) => {
  let link;
  let caption;
  if (record?.report === 'booksOnHands') {
    link = `/#/users/${record.currentRentedBy.user.id}`;
    caption = record['currentRentedBy'].user.telegram.username;
  } else if (record?.report === 'residentsWithExpiredReturn') {
    link = `/#/users/${record.id}`;
    caption = record.telegram.username;
  }
  return link && caption
    ? <a href={link}>
      {caption}
      </a>
    : <LinearProgress/>;
}

const BuildReportResult = ({ hasEdit, hasList, hasShow, data, ...props }) => {
  const { filterValues: { selectedReport } } = useListContext();
  
  const preparedData = Object.entries(data)
    .reduce((accum, [key, fieldData]) => {
      if (fieldData.report === selectedReport) {
        return {...accum, [key]: fieldData};
      }
      return accum;
    }, {});
  if (selectedReport === 'booksOnHands') {
    return <Datagrid
      key={`datagrid-${selectedReport}`}
      rowClick={() => false}
      isRowSelectable={() => false}
      {...props}
      data={preparedData}>
      <TextField source={'bookNumber'} label={'Номер книги'}/>
      <LinkToBookEdit source={'names[0].fullName'} label={'Название'}/>
      <TextField source={'location.name'} label={'Локация'}/>
      <FunctionField
        label={'Резидент'}
        render={({currentRentedBy: {user} = {}}) => {
          const {name, phone, telegram} = user;
          return `${name} +${phone}, ${telegram?.id}`.trim();
        }}
      />
    </Datagrid>
  } else if(selectedReport === 'allBooksByLocation') {
    return <Datagrid
      key={`datagrid-${selectedReport}`}
      rowClick={() => false}
      isRowSelectable={() => false}
      {...props}
      data={preparedData}>
      <TextField source={'bookNumber'} label={'Номер книги'}/>
      <LinkToBookEdit source={'names[0].fullName'} label={'Название'}/>
      <TextField source={'location.name'} label={'Локация'}/>
      <FunctionField
        label={'Резидент'}
        render={({ currentRentedBy: { user } = {}}) => {
          if (!user) {
            return '';
          }
          const { name, phone, telegram } = user;
          return `${name} +${phone}, ${telegram?.id}`.trim();
        }}
      />
    </Datagrid>
  } else if (selectedReport === 'residentsWithExpiredReturn') {
    return <Datagrid
      key={`datagrid-${selectedReport}`}
      rowClick={() => false}
      isRowSelectable={() => false}
      {...props}
      data={preparedData}>
      <FunctionField
        label={'Резидент'}
        render={({ name, phone, telegram }) => {
          return `${name} +${phone}, telegram: ${telegram?.id}`.trim();
        }}
      />
      <TextField source={'book.bookNumber'} label={'Номер книги'}/>
      <LinkToBookEdit source={'book.names[0].fullName'} label={'Название'}/>
      <TextField source={'location.name'} label={'Локация'}/>
      <NumberField source={'expired'} label={'Превышение в днях'}/>
    </Datagrid>;
  } else if (selectedReport === 'mostOftenLocation') {
    return <Datagrid
      key={`datagrid-${selectedReport}`}
      rowClick={() => false}
      isRowSelectable={() => false}
      {...props}
      data={preparedData}>
      <FunctionField render={({ name }) => { return name ?? 'Без названия';}} label={'Локация'}/>
      <NumberField source={'total'} label={'Всего аренд'}/>
    </Datagrid>;
  } else if (selectedReport === 'todayTakenAndReturn') {
    return <Datagrid
      key={`datagrid-${selectedReport}`}
      rowClick={() => false}
      isRowSelectable={() => false}
      {...props}
      data={preparedData}>
      <TextField source={'name'} label={'Локация'}/>
      <NumberField source={'todayTaken'} label={'Сегодня взяли'}/>
      <NumberField source={'todayReturned'} label={'Сегодня вернули'}/>
    </Datagrid>;
  } else if (selectedReport === 'expiredBooks') {
    return <Datagrid
      key={`datagrid-${selectedReport}`}
      rowClick={() => false}
      isRowSelectable={() => false}
      {...props}
      data={preparedData}>
      <TextField source={'name'} label={'Локация'}/>
      <NumberField source={'total'} label={'Всего книг'}/>
      <NumberField source={'rented'} label={'Арендовано книг'}/>
      <NumberField options={{ style: 'percent' }} source={'parted'} label={'Доля просроченных книг'}/>
    </Datagrid>;
  }
  return null;
};


const exporter = (listOfRecords, { location: { search } }) => {
  const isExportToCsvAllowed = search.includes('allBooksByLocation')
  if (isExportToCsvAllowed) {
    const mappedFields = listOfRecords.map(
      ({ bookNumber, description, status, names, authors }) => {
        return {
          ['номер книги']: bookNumber,
          ['полное название']: names[0].fullName,
          ['короткое название']: names[0].shortName,
          ['описание']: description,
          ['авторы']: authors.map(({ name }) => name).join(','),
          ['статус']: status,
        };
      }
    )
    jsonExport(mappedFields, {
      headers: [
        'номер книги',
        'полное название',
        'короткое название',
        'описание',
        'авторы',
        'статус',
      ],
    }, (err, csv) => {
      downloadCSV(csv, 'all-books-by-location');
    });
  }
};


export const ListStats = props => {
  return <List
    {...props}
    actions={<ListActions/>}
    empty={false}
    filters={<StatsFilters/>}
    title={'Отчёты'}
    exporter={(records) => exporter(records,props)}
  >
    <BuildReportResult {...props}/>
  </List>
};
