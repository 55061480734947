import React from 'react';
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const EditUsers = (props) => {

  return (
    <Edit {...props} title={'Изменение данных читателя'} mutationMode="pessimistic">
      <SimpleForm redirect={'list'}>
        <TextInput source="telegram.id" label={'Telegram id'} color={'primary'} variant={'outlined'}/>
        <TextInput source="telegram.username" label={'Username'} color={'primary'} variant={'outlined'}/>
        <TextInput source="name" label={'Имя'} color={'primary'} variant={'outlined'}/>
        <TextInput source="telegram.lastName" label={'Фамилия'} color={'primary'} variant={'outlined'}/>
        <TextInput source="phone" label={'Телефонный номер'} color={'primary'} variant={'outlined'}/>
        <ReferenceInput reference="locations" source="location" label={'Локация'} variant={'outlined'}>
          <SelectInput optionText="name" variant={'outlined'}/>
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
