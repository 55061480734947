import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNotify, useMutation } from 'react-admin';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
import { Row, Column, Item } from '@mui-treasury/components/flex';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';

const useBasicProfileStyles = makeStyles(() => ({
  avatar: {
    borderRadius: 8,
    backgroundColor: '#495869',
  },
  overline: {
    fontSize: 10,
    textTransform: 'uppercase',
    letterSpacing: 1,
    color: '#8D9CAD',
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    color: '#495869',
  },
}));

const BasicProfile = ({ authors, ...props }) => {
  const styles = useBasicProfileStyles();
  return (
    <Row {...props}>
      <Item><Avatar className={styles.avatar}>А</Avatar></Item>
      <Item position={'middle'} pl={{ sm: 0.5, lg: 0.5 }}>
        <Typography className={styles.overline}>{ authors.length > 1 ? 'Авторы' : 'Автор' }</Typography>
        <Typography className={styles.name}>
          {authors.map(({ name }) => name)}
        </Typography>
      </Item>
    </Row>
  );
};

const useCardHeaderStyles = makeStyles(() => ({
  root: { paddingBottom: 0 },
  titleWrap: {
    minHeight: '2rem',
  },
  title: {
    fontSize: '1rem',
    color: '#122740',
  },
  subheader: {
    fontSize: '0.875rem',
    color: '#495869',
  },
  subheaderPush: {
    fontSize: '0.875rem',
    color: '#495869',
    paddingTop: '0.5rem',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}));

const SendPushButton = props => {
  const style = useCardHeaderStyles();
  const notify = useNotify();
  const [, setLoadingState] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();
  const { bookId, bookTitle, telegram: { id: telegramId } = {} } = props || {};
  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const [sendPush, { loading }] = useMutation(
    {
      type: 'update',
      resource: 'users',
      payload: {
        id: props.userId,
        data: {
          update: { 'telegram.id': telegramId },
          $set: { 'rentedBooks.$[bookInArray].pushSentAt': moment() },
          arrayFilters: [{ 'bookInArray.book': bookId }],
          sendNotification: true,
          notify: {
            chat: telegramId,
            bookTitle,
          }
        }
      },
    },
    {
      onSuccess: () => {
        setSuccess(true);
        setLoadingState(false);
        timer.current = setTimeout(() => {
          setSuccess(false);
        }, 2000);
        
        notify('push.send.success');
      },
      onFailure: (error) => {
        setSuccess(false);
        setLoadingState(false);
        return notify('push.send.failure', 'warning');
      },
    }
  );

  return success
    ? <IconButton className={style.buttonSuccess}><CheckIcon/></IconButton>
    : <Button variant="outlined"
              color={'primary'}
                 size={'small'}
                 endIcon={<SendIcon/>}
                 onClick={sendPush}
                 disabled={loading}>
    { loading ? <CircularProgress size={20} color="secondary" /> : 'Попросить вернуть' }
    </Button>;
};

const CardHeader = ({
  bookId,
  bookNumber,
  bookTitle,
  expectedRentFinish,
  gap,
  prolongTimes,
  pushSentAt,
  rentStarted,
  telegram,
  userId,
  ...props
}) => {
  const styles = useCardHeaderStyles();
  return (
      <Grid container>
        <Grid item xs={12}>
          <Row {...props}>
            <Item position={'middle'} className={styles.titleWrap}>
              <Typography className={styles.title}>
                <b>{ bookTitle }</b>
              </Typography>
            </Item>
          </Row>
          <Row {...props}>
            <Grid container justify={'space-around'} spacing={2}>
              <Grid item xs={8}>
                <Item position={'middle'}>
                  <Typography className={styles.subheader}>
                    Номер книги: { bookNumber }
                  </Typography>
                  <Typography className={styles.subheader}>
                    Аренда с: { rentStarted }
                  </Typography>
                  <Typography className={styles.subheader}>
                    Ожидаемая дата возврата: { expectedRentFinish }
                  </Typography>
                  <Typography className={styles.subheader}>
                    Доступно продлений: { 1 - prolongTimes }/1
                  </Typography>
                </Item>
              </Grid>
              <Grid item xs style={{
                marginLeft: '-1rem',
                paddingRight: '1rem',
              }}>
                <Item position={'middle'} style={{ width: '100%', textAlign: 'center'}}>
                  <Typography className={styles.subheaderPush}>
                    Последний push
                  </Typography>
                  <Typography className={styles.subheaderPush}>
                    {pushSentAt
                      ? `${(new Date(pushSentAt)).toLocaleDateString('ru-RU')} ${(new Date(pushSentAt)).toLocaleTimeString('ru-RU')}`
                      : 'ещё не отправлено'}
                  </Typography>
                  <Typography className={styles.subheaderPush}>
                   <SendPushButton
                     bookId={bookId}
                     bookTitle={bookTitle}
                     telegram={telegram}
                     userId={userId}
                   />
                  </Typography>
                </Item>
              </Grid>
            </Grid>
          </Row>
        </Grid>
      </Grid>
    
  );
};

const useStyles = makeStyles(() => ({
  card: {
    border: '2px solid',
    borderColor: '#E7EDF3',
    borderRadius: 16,
    transition: '0.4s',
    '&:hover': {
      borderColor: '#5B9FED',
    },
  },
}));

export const ShowcaseCardDemo = React.memo(function ShowcaseCard(props) {
  const styles = useStyles();
  const gap = { xs: 1 }
  return (

        <Column className={styles.card} p={{ xs: 0.5 }} gap={gap}>
          <CardHeader
            bookId={props.bookId}
            bookNumber={props.bookNumber}
            bookTitle={props.bookTitle}
            rentStarted={props.rentStarted}
            expectedRentFinish={props.expectedRentFinish}
            gap={gap}
            prolongTimes={props.prolongTimes}
            pushSentAt={props.pushSentAt}
            telegram={props.telegram}
            userId={props.userId}
          />
          <BasicProfile authors={props.authors}/>
        </Column>

  );
});
export default ShowcaseCardDemo
