import React, { useState } from 'react';
import {
  AutocompleteInput,
  ChipField,
  Datagrid,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  useCreate,
  useNotify,
  useRefresh,
  useUpdate,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import ShowcaseCardDemo from './RentBookCard';
import Divider from '@material-ui/core/Divider';
import { Button, Card, CardActions, CardContent, Input } from '@material-ui/core';
import { Send } from '@material-ui/icons';

const UsersFilter = props => {
  return (
    <Filter { ...props } variant={'outlined'}>
      <TextInput
        alwaysOn
        resettable
        source="q"
        variant={'outlined'}
        label={'Поиск телефон/user'}
      />
      <ReferenceInput
        alwaysOn
        allowEmpty
        emptyText={'Все локации'}
        label={ 'Локация' }
        source={ 'location' }
        reference="locations"
      >
        <AutocompleteInput
          optionText="name"
          variant={'outlined'}
        />
      </ReferenceInput>
    </Filter>
  );
};

const RentedBooksArrayCard = props => {
  const { rentedBooks = [], telegram, id: userId } = props.record || {};
  const RentedBooksCard = Object.values(rentedBooks)
    .map(bookData => {
      const {
        book: {
          authors,
          bookNumber,
          id: bookId,
          names: [{ fullName }],
        },
        pushSentAt,
        prolongTimes,
        expectedRentFinish,
        rentStarted,
      } = bookData;
      const rentStartedMoment = `${(new Date(rentStarted)).toLocaleDateString('ru-RU')} ${(new Date(rentStarted)).toLocaleTimeString('ru-RU')}`;
      const rentExpectFinishMoment = moment(expectedRentFinish, 'YYYY-MM-DD').format('DD.MM.YYYY');
      return <React.Fragment key={`rented-book-details-${bookId}`}>
        <Grid item xs={12} sm={6}>
          <ShowcaseCardDemo
            bookId={bookId}
            bookNumber={bookNumber}
            bookTitle={fullName}
            rentStarted={rentStartedMoment}
            expectedRentFinish={rentExpectFinishMoment}
            authors={authors}
            prolongTimes={prolongTimes}
            pushSentAt={pushSentAt}
            telegram={telegram}
            userId={userId}
          />
        </Grid>
      </React.Fragment>;
    });
  return RentedBooksCard;
};

const ExpandUserList = ({ id, record, resource, ...rest }) => {
  const [directMessage, setDirectMessage] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('0000');
  const refresh = useRefresh();
  const [create, { isLoading, error }] = useCreate(
    'direct-messages',
    { message: directMessage, userId: record.telegram.id },
  );
  const [requestNewConfirmationCode] = useUpdate(
    'sms',
    id,
    { id, opCode: 'requestNewConfirmationCode' },
    {},
    {
      onSuccess: () => {
        refresh();
        notify('sms.newQuery.success');
      },
      onError: () => {
        notify('sms.newQuery.failed', { type: 'warning' });
      },
    },
  );
  const [enteredConfirmationCode] = useUpdate(
    'sms',
    id,
    { id, opCode: 'confirmationCodeEntered', confirmationCode },
    record,
    {
      onSuccess: () => {
        refresh();
        notify('sms.manualConfirmation.success');
      },
      onError: () => {
        notify('sms.manualConfirmation.failed', { type: 'warning' });
      },
    },
  );
  const notify = useNotify();
  
  const handleQueryNewConfirmationCode = () => {
    requestNewConfirmationCode();
    refresh();
  }
  
  const handleConfirmationCodeEntered = () => {
    enteredConfirmationCode();
    refresh();
  }
  
  const handleSendMessageButton = () => {
    if (`${directMessage}`.trim().length === 0) {
      return;
    }
    create();
    let message = 'push.send.success';
    if (error) {
      message = 'push.send.failure';
    }
    notify(message, { type: error ? 'danger' : 'success' });
    setDirectMessage('');
  };
  
  const { isRegistered } = record;
  
  return (
    <Grid container justify={'space-evenly'} spacing={2}>
      <Grid item xs={8}>
        <Grid item xs={12}>
          <Typography variant={'h6'}>Книги на руках</Typography>
          <Divider/>
        </Grid>
        {record.rentedBooks.length <= 0
        && <Grid item xs={12}>
          <Typography variant={'subtitle2'}>книг не найдено</Typography>
        </Grid>
        }
        {record.rentedBooks.length > 0 && <RentedBooksArrayCard record={record} {...rest}/>}
      </Grid>
      <Grid item xs={4}>
        {!isRegistered && <Grid item xs={12}>
          <Card style={{ backgroundColor: '#EAECEE' }} raised={false}>
            <CardContent>
              <Typography variant={'h5'} style={{ fontSize: 18, justifyContent: 'center', marginBottom: 3 }}>
                Регистрация пользователя
              </Typography>
              <Divider style={{ marginBottom: 8 }}/>
              <Typography variant={'body1'} style={{ fontSize: 16, justifyContent: 'center' }}>
                Номер телефона: {record.phone ? record.phone : 'не указан'}
              </Typography>
              {record.phone && record.confirmationCode.sentCode && <Typography variant={'body1'} style={{ fontSize: 16, justifyContent: 'center' }}>
                Отправлен:
                <>&nbsp;</>
                {new Date(record.confirmationCode.sentAt).toLocaleTimeString('ru-RU')}
                <>&nbsp;</>
                {new Date(record.confirmationCode.sentAt).toLocaleDateString('ru-RU')}
              </Typography>}
              {record.phone && record.confirmationCode.sentCode && <Typography variant={'body1'} style={{ fontSize: 16, justifyContent: 'center' }}>
                Попыток ввода: {record.confirmationCode.attemptCount}
              </Typography>}
              {record.phone && record.confirmationCode.sentCode && <Typography variant={'body1'} style={{ fontSize: 16, justifyContent: 'center' }}>
                Отправитель: {record.confirmationCode.sentBy === 'manual'
                ? 'Из админ панели'
                : 'Телеграм бот'
              }
              </Typography>}
              {record.confirmationCode.sentBy === 'manual' && (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Input
                    fullWidth={false}
                    onChange={({ target: { value } }) => setConfirmationCode(value)}
                    value={confirmationCode}
                    name={'Код из смс'}
                    variant={'standard'}
                  />
                  <Button
                    size={'small'}
                    color={'primary'}
                    variant={'outlined'}
                    onClick={handleConfirmationCodeEntered}>
                    Проверить код
                  </Button>
                </div>
              )}
              <Divider style={{ marginTop: 5 }}/>
            </CardContent>
            <CardActions style={{ justifyContent: 'center' }}>
              <Button
                size={'small'}
                variant={'outlined'}
                color={'primary'}
                onClick={handleQueryNewConfirmationCode}
                disabled={!record.phone}
                endIcon={<Send/>}
              >Отправить код в смс</Button>
            </CardActions>
          </Card>
        </Grid>}
        <Grid item xs={12}>
          <Card style={{ marginTop: 10, backgroundColor: '#EBF5FB' }}>
            <CardContent style={{ paddingTop: 0 }}>
              <Typography variant={'h5'} style={{ fontSize: 18, justifyContent: 'center' }}>Сообщения</Typography>
              <Divider />
              <Input
                fullWidth={true}
                multiline={true}
                onChange={({ target: { value } }) => setDirectMessage(value)}
                value={directMessage}
                name={'Текст сообщения'}
                variant={'standard'}
              />
            </CardContent>
            <CardActions style={{ justifyContent: 'center' }}>
              <Button
                size={'small'}
                variant={'outlined'}
                color={'primary'}
                onClick={handleSendMessageButton}
                disabled={isLoading}
                endIcon={<Send />}
              >Отправить</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ListUsers = props => (
  <List
    {...props}
    filters={<UsersFilter/>}
    sort={{ field: 'telegram.username', order: 'ASC' }}
    title={'Читатели библиотеки'}
  >
    <Datagrid rowClick="edit" expand={<ExpandUserList/>}>
      <TextField source="telegram.id" label={'Telegram id'} color={'primary'}/>
      <TextField source="telegram.username" label={'Username'} color={'primary'}/>
      <FunctionField
        render={({ name, telegram: { firstName = '', lastName = '' } }) => {
          return name
            ? `${name}`.trim()
            : `${lastName} ${firstName}`.trim();
        } }
        label={'Имя'} />
      <TextField source="phone" label={'Телефонный номер'} color={'primary'}/>
      <ReferenceField reference="locations" source="location" label={'Локация'}>
        <ChipField source="name" variant={'outlined'} color={'primary'} size={'small'}/>
      </ReferenceField>
      <FunctionField
        render={({ isRegistered }) => isRegistered ? 'Зарегистрирован' : 'Регистрация не завершена'}
        label={'Статус читателя'}
        color={'primary'}
      />
    </Datagrid>
  </List>
);
