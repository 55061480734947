import React from 'react';
import {
  ArrayInput,
  Edit,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

export const EditLocations = (props) => {
  return (
    <Edit {...props} title={'Редактирование локации'}>
      <SimpleForm redirect={'list'}>
        <TextInput source="name" label={'Название'} variant={'outlined'}/>
        <TextInput source="address.city" label={'Город'} variant={'outlined'}/>
        <TextInput source="address.full" label={'Адрес'} fullWidth={true} variant={'outlined'}/>
        <ArrayInput source="administrators" label={'Администратор'} fullWidth={true} variant={'outlined'}>
          <SimpleFormIterator>
            <TextInput source="fullName" label={'Имя администратора'} variant={'outlined'}/>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="coordinates.lat" label={'Широта'} variant={'outlined'}/>
        <TextInput source="coordinates.lng" label={'Долгота'} variant={'outlined'}/>
        <ArrayInput source="phones" label={'Телефоны'} fullWidth={true} variant={'outlined'}>
          <SimpleFormIterator>
            <TextInput source="number" label={'номер'} variant={'outlined'}/>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
