import React from 'react';
import {
  ArrayInput,
  Edit,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { BookStateChoices, BookStatusChoices, OriginalSourceChoices } from './Books.constants';

const WithoutEmptyGenresSelect = ({ choices, ...props }) => {
  const hasChoices = choices.length > 0 && choices.every(choice => !!choice);
  const notEmptyChoices = choices.filter(choice => !!choice);
  return !hasChoices
    ? <SelectInput
      variant="outlined"
      record={{ noGenres: 'notDefined' }}
      choices={[{ id: 'notDefined', name: 'Жанры не определены' }]}
      source={'noGenres'}
      label={'Жанры'}
    />
    : <SelectArrayInput
      {
        ...props
      }
      choices={notEmptyChoices}
      optionText="name"
      variant={'outlined'}
      color={'primary'}
      label={'Жанры'}
    />;
};

export const EditBooks = (props) => {
  return (
    <Edit {...props} title={'Редактирование книги'} undoable={false}>
      <SimpleForm redirect={'list'}>
        <ArrayInput source="authors" label={'Авторы'} variant={'outlined'}>
          <SimpleFormIterator>
            <TextInput source="name" label={'Имя автора'}/>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="manufacturer" label={'Издательство'}  variant={'outlined'}/>
        <TextInput source="bookNumber" label={'Номер книги'} variant={'outlined'}/>
        <ReferenceInput reference="locations" source="location" label={'Локация'} variant={'outlined'}>
          <SelectInput optionText="name" variant={'outlined'}/>
        </ReferenceInput>
        <ReferenceArrayInput reference="genres" source="genres" label={'Жанры'}>
          <WithoutEmptyGenresSelect optionText="name" variant={'outlined'} color={'primary'}/>
        </ReferenceArrayInput>
        <TextInput source="description" label={'Описание'} variant={'outlined'} fullWidth={true}/>
        <ArrayInput source="names" variant={'outlined'} label={'Названия книги'}>
          <SimpleFormIterator>
            <TextInput source="fullName" label={'Полное название'} variant={'outlined'}/>
            <TextInput source="shortName" label={'Короткое название'} variant={'outlined'}/>
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput label={'Источник'} source="originalSource" choices={OriginalSourceChoices} variant={'outlined'}/>
        <NumberInput source={'pages'} label={'Страниц'} variant={'outlined'}/>
        <NumberInput source={'price'} label={'Цена'} variant={'outlined'}/>
        <SelectInput source="state" label={'Состояние книги'} choices={BookStateChoices} variant={'outlined'}/>
        <SelectInput source="status" label={'Статус'} choices={BookStatusChoices} variant={'outlined'}/>
        <NumberInput source={'weight'} label={'Вес'} variant={'outlined'}/>
      </SimpleForm>
    </Edit>
  );
};
