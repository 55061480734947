export const OriginalSourceChoices = [
  { id: 'praktik', name: 'Практик' },
  { id: 'resident', name: 'Резидет' },
];

export const BookStateChoices = [
  { id: 'fine', name: 'Отличное' },
  { id: 'good', name: 'Хорошее' },
  { id: 'average', name: 'Среднее' },
  { id: 'poor', name: 'Плохое' },
];

export const BookStatusChoices = [
  { id: 'inLibrary', name: 'В библиотеке' },
  { id: 'rented', name: 'На руках у резидента' },
  { id: 'notForRent', name: 'Не для аренды' },
  { id: 'onRegistration', name: 'На регистрации' },
];
