import React from 'react';
import {
  ArrayInput,
  Create,
  NumberInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { BookStateChoices, BookStatusChoices, OriginalSourceChoices } from './Books.constants';

export const CreateBooks = (props) => {
  return (
    <Create {...props} title={'Добавление нового экрана бота'}>
      <SimpleForm redirect={'list'}>
        <ArrayInput source="authors" label={'Авторы'}>
          <SimpleFormIterator>
            <TextInput source="name" label={'Имя'}/>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="manufacturer" label={'Издательство'}/>
        <TextInput source="bookNumber" label={'Номер книги'}/>
        <ReferenceInput reference="locations" source="location" label={'Локация'} variant={'outlined'}>
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceArrayInput reference="genres" source="genres" label={'Жанры'} variant={'outlined'}>
          <SelectArrayInput optionText="name" variant={'outlined'} color={'primary'}/>
        </ReferenceArrayInput>
        <TextInput fullWidth={true} source="description" label={'Описание'}/>
        <ArrayInput source="names" fullWidth={true} variant={'outlined'}>
          <SimpleFormIterator>
            <TextInput source="fullName" label={'Полное название'}/>
            <TextInput source="shortName" label={'Короткое название'}/>
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput
          label={'Источник'}
          source={'originalSource'}
          choices={OriginalSourceChoices}
          variant={'outlined'}/>
        <NumberInput source={'pages'} label={'Страниц'} variant={'outlined'}/>
        <NumberInput source={'price'} label={'Цена'} variant={'outlined'}/>
        <SelectInput source="state" label={'Состояние книги'} choices={BookStateChoices} variant={'outlined'}/>
        <SelectInput source="status" label={'Статус'} choices={BookStatusChoices} variant={'outlined'}/>
        <NumberInput source={'weight'} label={'Вес'} variant={'outlined'}/>
      </SimpleForm>
    </Create>
  );
};
