import React from 'react';
import { Edit, SimpleForm, TextField, TextInput, NumberInput } from 'react-admin';

const DateRangeInput = (props) => {
  
  return (
    <>
      <div>
        <NumberInput {...props} source={'value.startHour'} label={'Начало час'}/>
        <NumberInput {...props} source={'value.startMinute'} label={'Начало минуты'}/>
      </div>
      <div>
        <NumberInput {...props} source={'value.endHour'} label={'Конец час'}/>
        <NumberInput {...props} source={'value.endMinute'} label={'Конец минуты'}/>
      </div>
    </>
  );
}

const TextInputWithDateRange = (props) => {
  const { record } = props;
  if (record.name === 'sendPushedAllowedPeriod') {
    return <DateRangeInput />
  } else {
    return <TextInput record={record} source={'value'} />
  }
}

export const EditSettings = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField source={'name'} />
        <TextInput source={'description'} fullWidth={true} />
        <TextInputWithDateRange />
      </SimpleForm>
    </Edit>
  );
};
