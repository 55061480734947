export const ScreenNameChoices = [
  { id: 'startScreen', name: 'Стартовый экран'},
  { id: 'bookDetails', name: 'Детали книги'},
  { id: 'searchBookScreen', name: 'Поиск книги'},
  { id: 'findBookByTitle', name: 'Поиск по названию'},
  { id: 'genreScreen', name: 'Жанры'},
  { id: 'listGenre', name: 'Список жанров'},
  { id: 'listAllBooks', name: 'Список всех книг'},
  { id: 'prolongBook', name: 'Продлене аренды'},
  { id: 'rentBook', name: 'Аренда книги'},
  { id: 'rentRules', name: 'Правила аренды'},
  { id: 'returnBook', name: 'Возврат книги'},
  { id: 'returnBooks', name: 'Экран возврата книг'},
  { id: 'shareBook', name: 'Поделиться книгой'},
  { id: 'tooManyBooksRented', name: 'Много книг арендовано'},
  { id: 'pushBringBack', name: 'Пуш: возврат книги в библиотеку'},
];
