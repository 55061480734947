import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';

const DisplayValue = (props) => {
  const { record } = props;
  if (record.name === 'sendPushedAllowedPeriod') {
    const { value: allowedPeriod } = record;
    const startHour = allowedPeriod.startHour < 10 ? `0${allowedPeriod.startHour}` : allowedPeriod.startHour;
    const startMinute = allowedPeriod.startMinute < 10 ? `0${allowedPeriod.startMinute}` : allowedPeriod.startMinute;
    const endHour = allowedPeriod.endHour < 10 ? `0${allowedPeriod.endHour}` : allowedPeriod.endHour;
    const endMinute = allowedPeriod.endMinute < 10 ? `0${allowedPeriod.endMinute}` : allowedPeriod.endMinute;
    return <TextField
      record={{ value: `${startHour}:${startMinute}-${endHour}:${endMinute}` }}
      source="value"
      label={'Значение'}
    />;
  } else {
    return <TextField record={record} source="value" label={'Значение'}/>;
  }
}

export const ListSettings = (props) => {
  return (
    <List
      {...props}
      title={'Настройки'}
    >
      <Datagrid rowClick="edit" isRowSelectable={() => false}>
        <TextField source="description" label={'Описание'}/>
        <DisplayValue />
      </Datagrid>
    </List>
  );
};
