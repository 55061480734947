import React from 'react';
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { ScreenNameChoices } from './ConstsScreen';

export const CreateScreens = (props) => {
  return (
    <Create {...props} title={'Добавление нового экрана бота'}>
      <SimpleForm redirect={'list'}>
        <SelectInput source={'screen'} choices={ScreenNameChoices} label={'Название экрана'}/>
        <TextInput fullWidth={true} source="text"/>
      </SimpleForm>
    </Create>
  );
};
