import React from 'react';
import { ArrayField, ChipField, Datagrid, Filter, List, SingleFieldList, TextField, TextInput, } from 'react-admin';

const SearchFilter = props => {
  return (
    <Filter
      { ...props }
      variant={'outlined'}
    >
      <TextInput
        alwaysOn
        resettable
        source="q"
        fullWidth={true}
        variant={'outlined'}
        label={props.label || 'Search'}
      />
    </Filter>
  );
};

const TelegramUrlField = props => {
  const { record } = props;
  const { fullName } = record;
  
  const telegramLink = `https://t.me/${fullName.replace('@', '')}`;
  const handleOnClick = (event) => {
    event.preventDefault();
    if (fullName.startsWith('@')) {
      window.open(telegramLink, '_blank', 'noopener,noreferrer');
    }
  }
  
  return (
    <ChipField
      onClick={handleOnClick}
      clickable={true}
      record={{ fullName }}
      source="fullName"
      color={'primary'}
      size={'small'}
      variant={'outlined'}
    />
  );
};

export const ListLocations = props => (
  <List
    {...props}
    filters={<SearchFilter label={'Поиск название/город/адрес'}/>}
    sort={{ field: 'name', order: 'ASC' }}
    title={'Локации'}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label={'название'}/>
      <TextField source="address.city" label={'Город'}/>
      <TextField source="address.full" label={'Адрес'}/>
      <ArrayField source="administrators" label={'Администратор'}>
        <SingleFieldList>
          <TelegramUrlField />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="phones" label={'Телефоны'}>
        <SingleFieldList>
          <ChipField source="number" color={'primary'} size={'small'} variant={'outlined'}/>
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);
