// TODO: move to styles
import { makeStyles } from '@material-ui/core/styles';

export const ListBooksStyles = makeStyles({
  gridWrapper: {
    minHeight: '3rem',
    padding: '0 1rem',
  },
  paper: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    width: '100%',
    textAlign: 'center',
    minHeight: '2.5rem',
  },
  authorPaper: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  qrCodePaper: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem',
    padding: 5,
    textAlign: 'center',
  },
  gridPadding: {
    paddingRight: '2rem',
  },
  rowStyle: { textAlign: 'left', padding: '0.5rem' },
  rowStyleCenter: { textAlign: 'center', padding: '0.5rem' },
  rowStyleCenterFullWidth: { width: '100%', textAlign: 'center', padding: '0.5rem' }
});