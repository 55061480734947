import React from 'react';
import { List, Datagrid, SelectField, TextField, Filter, TextInput } from 'react-admin';
import { ScreenNameChoices } from './ConstsScreen';

const SearchFilter = props => {
  return (
    <Filter { ...props } variant={'outlined'}>
      <TextInput
        alwaysOn
        resettable
        source="q"
        variant={'outlined'}
        label={props.label || 'Search'}
      />
    </Filter>
  );
};

export const ListScreens = props => (
  <List
    {...props}
    filters={<SearchFilter label={'Поиск в тексте'}/>}
    sort={{ field: 'screen', order: 'ASC' }}
    title={'Экраны бота'}
  >
    <Datagrid rowClick="edit">
      <SelectField source={'screen'} choices={ScreenNameChoices} label={'Название экрана'}/>
      <TextField source="text" label={'Текст'}/>
    </Datagrid>
  </List>
);
