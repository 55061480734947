module.exports = {
  csv: {
    main: {
      import: 'Импортировать',
    },
    error: {
      noId: "Overwrite requires field 'id'",
      hasId: "Create should not include field 'id'",
      importing: 'Ошибка при импорте из файла',
      emptyResource: "The 'resource' property was empty, did you pass in the {...props} to the ImportButton?",
    },
    alert: {
      imported: 'Импортировано',
    },
    dialog: {
      importTo: 'Импортировать в',
      dataFileReq: 'Data file requirements',
      extension: "Файл с раширением '.csv'",
      idColumnCreate: "Поле 'id' должно отсутствовать в файле для новых записей",
      idColumnUpdate: "Для перезаписи должно содержать поле 'id'",
      chooseFile: 'Выбрать файл',
      processed: 'Обработано',
      rowCount: 'Строк',
      cancel: 'Отмена',
      importNew: 'Испортировать ',
      importOverride: 'Перезаписать ',
    },
  },
  push: {
    send: {
      success: 'Сообщение отправлено',
      failure: 'Ошибка при отправке сообщения',
    },
  },
  sms: {
    newQuery: {
      success: 'Новый код подтверждения отправлен',
      failed: 'Ошибка при отправке нового кода подтверждения',
    },
    manualConfirmation: {
      success: 'Код подтверждения отправлен',
      failed: 'Ошибка при проверке кода подтверждения',
    },
  },
  ra: {
    action: {
      unselect: 'снять',
    },
    notification: { data_provider_error: 'Ошибка провайдера данных' }
  },
};
