import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { DynamicFeed } from '@material-ui/icons';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FontDownloadOutlinedIcon from '@material-ui/icons/FontDownloadOutlined';
import LabelIcon from '@material-ui/icons/Label';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PagesIcon from '@material-ui/icons/Pages';
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  FunctionField, NumberField,
  ReferenceArrayField,
  ReferenceField, RichTextField, SelectField,
  SingleFieldList, TextField,
  useGetOne
} from 'react-admin';
import { createPortal, render } from 'react-dom';
import { BarcodeTemplate } from '../../../barcode-template';
import { BookStateChoices, OriginalSourceChoices } from '../Books.constants';
import { ListBooksStyles } from '../styles/ListBooks.styles';
import { GenreOrEmptyChip } from './GenreOrEmptyChip';

function generateQrCode(bookNumber) {
  return Buffer.from(
    JSON.stringify({ command: `/takeBookQrCode ${bookNumber}` }),
    'ascii',
  ).toString('base64');
}

const PrintQrCode = ({ record }) => {
  const { bookNumber } = record;
  if (!bookNumber) {
    return <></>;
  }
  const base64Command = generateQrCode(bookNumber);
  const qrCodeContent = qrCodebasePath + base64Command;
  return (
    <>
      {base64Command
        &&
        <>
          <QRCodeCanvas
            level={'M'}
            size={128}
            value={qrCodeContent}
            id={`qrcode-rendered-book-${bookNumber}`}
          />
        </>
      }
    </>
  );
};

//TODO: move to model
const qrCodebasePath = process.env.REACT_APP_TELEGRAM_BOT_NAME || 'https://t.me/testLibaryBot?start=';

export const ExpandAdditionalProps = (props) => {
  const cssStyle = ListBooksStyles();
  const { id, record, resource, ...rest } = props;

  const [container, setContainer] = useState(null);
  const newWindow = useRef(null);

  useEffect(() => {
    if (container) {
      newWindow.current = window.open(
        "",
        "",
        "width=450px,height=650px,left=200,top=200",
      );
      newWindow.current.document.body.appendChild(container);

      const curWindow = newWindow.current;

      return () => curWindow.close();
    }
  }, [container]);

  const handleOnClickQrCodeDownload = async (record, genreNameWithNumber) => {
    const { bookNumber } = record;

    if (!bookNumber) {
      return;
    }
    const base64Command = generateQrCode(bookNumber);
    const qrCodeContent = qrCodebasePath + base64Command;

    const [maybeNumber, ...genreName] = `${genreNameWithNumber}`.split('.');
    const genreNumber = isNaN(maybeNumber) ? '' : Number(maybeNumber);

    const temporaryDiv = document.createElement('div');

    render(
      <BarcodeTemplate
        bookNumber={bookNumber}
        genreName={genreName}
        genreNumber={genreNumber}
        qrCode={
          <QRCodeCanvas
            level={'M'}
            size={128}
            value={qrCodeContent}
            id={`qrcode-rendered-book-${bookNumber}`}
          />
        }
      />,
      temporaryDiv,
    );

    setContainer(temporaryDiv);
    return container && createPortal(props.children, container);
  };

  const { genres: [genreId] } = record;
  const { data: { name } = {}, isLoading } = useGetOne('genres', genreId);

  return (
    <div
      key={`div-${record.id}`}
      style={{ display: 'block', minHeight: '12rem', overflowY: 'hidden', overflowX: 'hidden' }}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        className={ cssStyle.gridWrapper }
        spacing={ 2 }>
        <Grid item xs={ 4 }>
          <Grid container direction={'column'}>
            <Grid item xs={12} style={{ padding: '0.5rem'}}>
              <Paper style={{
                textAlign: 'left',
                minHeight: '3.5rem',
              }}>
                <Chip
                  size={'small'}
                  color={'primary'}
                  icon={<DynamicFeed/>}
                  label={'Жанры'}
                  variant={'outlined'}
                  style={{ marginBottom: 5, textAlign: 'left' }}
                />
                {record['genres'].length > 0
                  ? <ReferenceArrayField
                    record={ record }
                    reference={ 'genres' }
                    source={ 'genres' }
                    link={false}
                    { ...rest }
                  >
                    <SingleFieldList style={{ marginTop: '-4px' }}>
                      <GenreOrEmptyChip
                        icon={<LabelIcon />}
                        source="name"
                        variant={ 'outlined' }
                        color={ 'primary' }
                        size={ 'small' }/>
                    </SingleFieldList>
                  </ReferenceArrayField>
                  : <Typography
                    className={cssStyle.rowStyle} variant="body2">
                    Жанры для книги не определены
                  </Typography>
                }
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ padding: '0.5rem'}}>
              {record['rentedBy'].length > 0 ?
                <Grid item xs={ 12 } style={{ marginTop: '0.5rem'}}>
                  <Grid container direction={'row'} spacing={0}>
                    <Grid item xs={12}>
                      <Paper className={cssStyle.paper}>
                        <Typography
                          className={cssStyle.rowStyle} variant="body2">
                          Журнал аренды книги
                        </Typography>
                        <Divider/>
                        <table style={{ borderCollapse: 'collapse' }}>
                          <thead>
                          <tr>
                            <th>
                              <Typography
                                className={cssStyle.rowStyleCenter} variant="body2">
                                Дата
                              </Typography>
                            </th>
                            <th>
                              <Typography
                                className={cssStyle.rowStyleCenter} variant="body2">
                                Читатель
                              </Typography>
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          {record['rentedBy'].map(({ date, user }, index) => {
                            return <tr style={{ borderBottom: '1px solid #3d3d3d17' }} key={`libraryHist-${user}-${index}`}>
                              <td className={cssStyle.rowStyleCenter}>
                                <Typography variant={ 'subtitle2' } >
                                  { new Date(date).toLocaleDateString('ru-RU') }
                                </Typography>
                              </td>
                              <td className={cssStyle.rowStyleCenterFullWidth}>
                                <ReferenceField
                                  record={ { user } }
                                  reference="users"
                                  source="user"
                                  link={(record, reference) => `${reference}/${record.user}`}
                                  { ...rest }
                                >
                                  <FunctionField
                                    label="Name"
                                    render={ ({ telegram: { username, firstName }, name }) => (
                                      <Typography variant={ 'subtitle2' }>
                                        { firstName ?? username ?? name }
                                      </Typography>
                                    ) }
                                  />
                                </ReferenceField>
                              </td>
                            </tr>
                          })}
                          </tbody>
                        </table>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                : <Paper className={ cssStyle.paper }>
                  <Typography
                    className={cssStyle.rowStyleCenter}
                    variant="body2">
                    Эту книгу ещё не арендовали
                  </Typography>
                  <Divider/>
                </Paper>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                spacing={0}>
            <Grid item xs={12}>
              <Grid container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    spacing={2}
              >
                <Grid item xs>
                  <Paper className={cssStyle.paper}>
                    <Chip
                      label={<NumberField record={ record } source={ 'price' }/>}
                      icon={<AccountBalanceWalletIcon />}
                      variant={'outlined'}
                      size={'small'}
                      color={'primary'}
                    />
                    <Typography style={{ width: '100%', textAlign: 'center' }} variant="body2">Цена</Typography>
                  </Paper>
                </Grid>
                <Grid item xs>
                  <Paper className={cssStyle.paper}>
                    <Chip
                      label={<NumberField record={ record } source={ 'pages' }/>}
                      icon={<MenuBookIcon />}
                      variant={'outlined'}
                      size={'small'}
                      color={'primary'}
                    />
                    <Typography style={{ width: '100%', textAlign: 'center' }} variant="body2">Страниц</Typography>
                  </Paper>
                </Grid>
                <Grid item xs>
                  <Paper className={cssStyle.paper}>
                    <Chip
                      label={<SelectField
                        source="originalSource"
                        record={ record }
                        choices={ OriginalSourceChoices }
                      />}
                      icon={<LibraryAddIcon />}
                      variant={'outlined'}
                      size={'small'}
                      color={'primary'}
                    />
                    <Typography style={{ width: '100%', textAlign: 'center' }} variant="body2">Источник</Typography>
                  </Paper>
                </Grid>
                <Grid item xs>
                  <Paper className={cssStyle.paper}>
                    <Chip
                      label={<SelectField
                        record={ record }
                        source="state"
                        label={'Состояние книги'}
                        choices={BookStateChoices}/>}
                      icon={<PhotoFilterIcon />}
                      variant={'outlined'}
                      size={'small'}
                      color={'primary'}
                    />
                    <Typography style={{ width: '100%', textAlign: 'center' }} variant="body2">Состояние</Typography>
                  </Paper>
                </Grid>
                <Grid item xs>
                  <Paper className={cssStyle.paper}>
                    <Chip
                      label={<NumberField record={ record } source={ 'weight' }/>}
                      icon={<PagesIcon />}
                      variant={'outlined'}
                      size={'small'}
                      color={'primary'}
                    />
                    <Typography style={{ width: '100%', textAlign: 'center' }} variant="body2">Вес</Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            {record.currentRentedBy.user && <Grid item xs={ 12 } style={{ marginTop: '0.5rem'}}>
              <Grid container direction={'row'} spacing={0}>
                <Grid item xs={12}>
                  <Paper className={cssStyle.paper}>
                    <Typography
                      style={{ width: '100%', textAlign: 'left', padding: '0.5rem' }} variant="body2">
                      Эта книга сейчас у читателя
                    </Typography>
                    <Divider/>
                    <Grid container dir={'row'} style={{ padding: '0.5rem ' }}>
                      <Grid item xs={3}>
                        <Typography
                          style={{ width: '100%', textAlign: 'center', padding: '0.5rem' }} variant="body2">
                          Дата аренды
                        </Typography>
                        <FunctionField
                          record={record['currentRentedBy']}
                          render={({ date }) => (
                            <Chip
                              label={`${new Date(date).toLocaleDateString('ru-RU')}`}
                              variant={'outlined'}
                              color={'primary'}
                              size={'small'}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          style={{ width: '100%', textAlign: 'center', padding: '0.5rem' }} variant="body2">
                          Дата возврата
                        </Typography>
                        <FunctionField
                          record={record['currentRentedBy']}
                          render={({ date, prolongTimes }) => {
                            const rentDate = new Date(date);
                            const returnDate = prolongTimes ? rentDate.setDate(rentDate.getDate() + 14) : rentDate.setDate(rentDate.getDate() + 21);
                            return (
                              <Chip
                                label={ `${ new Date(returnDate).toLocaleDateString('ru-RU') }` }
                                variant={ 'outlined' }
                                color={ 'primary' }
                                size={ 'small' }
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <Typography
                          style={{ width: '100%', textAlign: 'center', padding: '0.5rem' }} variant="body2">
                          Контактные данные
                        </Typography>
                        <ReferenceField
                          record={record['currentRentedBy']}
                          reference="users"
                          source="user"
                          { ...rest }
                        >
                          <FunctionField
                            label="Name"
                            onClick={(e) => {e.preventDefault();}}
                            render={({ telegram, phone, name }) => (
                              <Chip
                                label={`${telegram.id} @${telegram.username ?? telegram.id} ${telegram.firstName ?? name} ${phone ? phone : '' }`}
                                variant={'outlined'}
                                color={'primary'}
                                size={'small'}
                              />
                            )}
                          />
                        </ReferenceField>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid> }
            <Grid item xs={12}>
              <Grid container direction={'row'} spacing={2} justify={'space-evenly'} alignItems={'flex-start'}>
                <Grid item xs={7}>
                  <Grid item xs={12}>
                    <Paper className={cssStyle.authorPaper}>
                      <Typography style={{ width: '100%', textAlign: 'left', padding: '0.5rem' }} variant="body2">Автор или авторы</Typography>
                      <Divider/>
                      {record['authors'].map(({ _id, name }) => (
                        <Grid item xs key={_id}
                              style={{ padding: '0.5rem' }}
                        >
                          <Chip
                            label={name}
                            icon={<AccountCircleIcon />}
                            variant={'outlined'}
                            size={'small'}
                            color={'primary'}
                          />
                        </Grid>
                      ))}
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={cssStyle.authorPaper}>
                      <Typography style={{ width: '100%', textAlign: 'left', padding: '0.5rem' }} variant="body2">Издательство</Typography>
                      <Divider/>
                      <Grid item xs style={{ padding: '0.5rem' }}>
                        <TextField
                          variant={'body2'}
                          size={'small'}
                          color={'primary'}
                          record={!!record['manufacturer'] ? record : { manufacturer: 'Не указано' }}
                          source={'manufacturer'}
                        />
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <Paper className={cssStyle.qrCodePaper}>
                    <Grid item xs={12} key={`show-qr-code-${record.id}`}>
                      <PrintQrCode record={record} genreName={name}/>
                    </Grid>
                    <Grid item xs={12} key={`download-qr-code-button-${record.id}`}>
                      <Button
                        disabled={isLoading}
                        color={'primary'}
                        label={'Скачать QR code'}
                        onClick={() => handleOnClickQrCodeDownload(record, name)}
                      />
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} key={`grid-names-${record.id}`}>
                  <Paper className={cssStyle.authorPaper} key={`paper-names-${record.id}`}>
                    <Typography style={{ width: '100%', textAlign: 'left', padding: '0.5rem' }} variant="body2">Названия книги</Typography>
                    <Divider/>
                    {record['names'].map(({ _id, fullName, shortName }) => (
                      <Grid item xs key={_id}>
                        <Grid item xs={12}
                              style={{ padding: '0.5rem' }}
                        >
                          <Grid item xs={12}>
                            <Typography variant="subtitle2" style={{ padding: '0.5rem' }}>Полное название</Typography>
                          </Grid>
                          <Grid item xs={12}
                                style={{ paddingLeft: '0.5rem' }}
                          >
                            <Chip
                              color={'primary'}
                              icon={<FontDownloadOutlinedIcon/>}
                              label={fullName}
                              size={'small'}
                              style={{ paddingLeft: 5 }}
                              variant={'outlined'}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}
                              style={{ padding: '0.5rem' }}
                        >
                          <Grid item xs={12}>
                            <Typography variant="subtitle2" style={{ padding: '0.5rem' }}>Короткое название</Typography>
                          </Grid>
                          <Grid item xs={12}
                                style={{ paddingLeft: '0.5rem' }}
                          >
                            <Chip
                              color={'primary'}
                              icon={<FontDownloadOutlinedIcon/>}
                              label={shortName}
                              size={'small'}
                              style={{ paddingLeft: 5 }}
                              variant={'outlined'}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} key={`grid-description-${record.id}`}>
              <Paper className={cssStyle.authorPaper} key={`paper-description-${record.id}`}>
                <Typography style={{ width: '100%', textAlign: 'left', padding: '0.5rem' }} variant="body2">Описание</Typography>
                <Divider/>
                <RichTextField style={{
                  padding: 10,
                  display: 'block',
                  textAlign: 'justify',
                }} key={`textField-description-${record.id}`} record={record} source={'description'}/>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
